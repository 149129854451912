import React from "react";
import { withRouter } from "react-router";
import OneSignal from 'react-onesignal';
import './Tarot.css';
import {isMobile} from 'react-device-detect';

class Tarot extends React.Component {
  constructor(props) {
    super(props);
    OneSignal.initialize('2e36e38d-d2f0-4fef-88cc-2f9cf2703f28');

    this.state = {
      selectedCards: this._checkHasPreviousResult() ? JSON.parse(localStorage.getItem('selectedCards')) : [],
      resultSMSCode: null,
      cards: [
        {number: 1, id: 1},
        {number: 2, id: 2},
        {number: 3, id: 3},
      ],
      resultCards: this._checkHasPreviousResult() ? JSON.parse(localStorage.getItem('resultCards')) : null
    };

    this.selectCard = this.selectCard.bind(this);

    this.rootRef = null;
    this.titleRef = null;
    this.cardsWrapperRef = null;
    this.cardRefs = {};

    this.resultSMSRef = null;
  }
  _checkHasPreviousResult() {
    var selectedCards = localStorage.getItem('selectedCards');
    var selectedCardsExpire = localStorage.getItem('selectedCardsExpire');
    if(selectedCards != null && selectedCardsExpire != null) {
      selectedCardsExpire = new Date(JSON.parse(selectedCardsExpire));
      if(selectedCardsExpire > new Date( Date.now() - 10 * 60000 )) {
        return true;
      }
    }
  }
  componentDidMount() {
    if(this.state.selectedCards.length > 0) {
      this.rootRef.classList.add('no-anim');
      for(const selectedCardIndex of this.state.selectedCards) {
        this._adjustSelectedCard(this.cardRefs[selectedCardIndex - 1]);
      }
      this.displayResults(true);
    }
  }
  render() {

      const { resultSMSCode, selectedCards } = this.state;

    return (
      <div class="container wrapper" ref={(instance)=>{this.rootRef = instance;}}>
          <div class="header">
              <h2 class="title"><img src="img/tarot-card-icong.png"/>LJUBAVNI TAROT</h2>
              <h4>Saznajte što vas čeka u ljubavi</h4>
              <p class="pink">Je li on/ona prava osoba za mene? Voli li me ili ne?<br/> Čeka li me brak i obitelj ili rastava?</p>
              <div className="title2" ref={(instance)=>{this.titleRef = instance;}}>
                <img src="img/heart.png" class="img-responsive"/>
                <h4 class="pink-2"><strong>Otkrijte kartu sa svake pozicije:</strong></h4>
              </div>
          </div>
          <div class="body">
              <div class="card-wrapper card-wrapper2" style={{marginTop: 'unset'}} ref={(instance)=>{this.cardsWrapperRef = instance;}}>
                  <div class="col-xs-4">
                      <div ref={(instance)=>{this.cardRefs[0] = instance;}} style={{position: "relative"}} onClick={this.selectCard} id={1}>
                          { !selectedCards.includes(1) &&
                              <img src="img/cards/card-back.jpg" className="img-responsive card-back"
                                   style={{cursor: "pointer", height: "100%"}}/>}
                        <img class="img-responsive card-front" style={{top: 0, bottom: 0, left: 0, right: 0, height: "100%"}}/>
                      </div>
                      <p class="pink card-title" style={{marginBottom: 'unset', marginTop: '10%'}}><strong>PROŠLOST</strong></p>
                      <p style={selectedCards.includes(1) ? {display: "none"} : {display: "block"}}><small class="card-subtitle">Poznaju li se naše duše već međusobno?</small></p>
                  </div>
                  <div class="col-xs-4" style={{position: "relative"}}>
                      <div ref={(instance)=>{this.cardRefs[1] = instance;}} style={{position: "relative"}} onClick={this.selectCard} id={2}>
                          { !selectedCards.includes(2) &&
                              <img src="img/cards/card-back.jpg" className="img-responsive card-back"
                                   style={{cursor: "pointer", height: "100%"}}/>}
                        <img class="img-responsive card-front" style={{top: 0, bottom: 0, left: 0, right: 0,  height: "100%"}}/>
                      </div>
                      <p class="pink card-title" style={{marginBottom: 'unset', marginTop: '10%'}}><strong>SADAŠNJOST</strong></p>
                      <p style={selectedCards.includes(2) ? {display: "none"} : {display: "block"}}><small class="card-subtitle">Trebam li ulagati vrijeme u ovu vezu?</small></p>
                  </div>
                  <div class="col-xs-4"  style={{position: "relative"}}>
                      <div ref={(instance)=>{this.cardRefs[2] = instance;}} style={{position: "relative"}} onClick={this.selectCard} id={3}>
                          { !selectedCards.includes(3) &&
                              <img src="img/cards/card-back.jpg" className="img-responsive card-back"
                                   style={{cursor: "pointer", height: "100%"}}/>}
                        <img class="img-responsive card-front" style={{top: 0, bottom: 0, left: 0, right: 0, height: "100%"}}/>
                      </div>
                      <p class="pink card-title" style={{marginBottom: 'unset', marginTop: '10%'}}><strong>BUDUĆNOST</strong></p>
                      <p style={selectedCards.includes(3) ? {display: "none"} : {display: "block"}}><small class="card-subtitle">Kako izgleda budućnost ove veze?</small></p>
                  </div>
              </div>

              <div class="col-xs-12 hide-on-reveal" style={{margin: "20px 0"}}>
                  <p>Bez obzira jeste li slobodni ili ste u vezi, ove karte će vam dati odgovor koji tražite</p>
              </div>

              <div className="analiza-wrapper" style={(this.state.selectedCards.length === 3) ? {display: 'block'} : {display: 'none'}}>
                <div class="container">
                  <div class="col-xs-12" style={{margin: "10% 0"}}>
                      <p>Karte u ovom položaju vam pružaju uvid u ono što će vam se dogoditi.</p>
                      <p><strong>Jeste li već upoznali ili tek trebate upoznati svoju srodnu dušu?<br/>
                          Trebate li ulagati svoje vrijeme u vezu?<br/>
                          Kako će izgledati vaša budućnost?<br/>
                      </strong></p>
                      <p>Na vama je da donesete odluku i živite sa svim posljedicama - pozitivnim ili negativnim</p>
                      <p class="pink">Karte koje ste odabrali će vas savjetovati što učiniti!</p>
                      <a class="btn pulse-button padding-unset" style={{marginTop: '10%'}} ref={(instance)=>{this.resultSMSRef = instance;}}><img src="/img/btn.png" class="img-responsive"/></a>
                  </div>
                </div>
              </div>

              <div class="container" style={{display: `${(this.state.selectedCards.length === 3) ? 'flex' : 'none'}`, flexDirection: "column", flex: 1, justifyContent: "flex-end", marginTop: '1%'}}>
                <div className={isMobile ? "col-sm-offset-2" : "col-sm-8 col-sm-offset-2"}>
                  <div className="col-sm-12 note" style={{marginTop: 'unset !important'}}>
                    <div>
                      <p><small>Usluga je namjenjena samo za zabavu. Pošaljite SMS-om vaš osobni kod {resultSMSCode} na 862862. Saznajte odgovore u tarot kartama koje ste otvorili. Zabavite se uz astrološke sadržaje i otkrijte što vam donosi budućnost! Ovo je pretplatnička usluga. 9 kn/SMS max 2 x tjedno. Organizator usluge je: Comparo Media d.o.o., Tometići 15a, 51215, Kastav, OIB 80948414608. Tehnička služba: NTH Mobile d.o.o. Međimurska 28, 42 000 Varaždin, MB: 070096612, OIB 12385860076. Služba za korisnike: 09:00 - 17:00 sati na 042500871 ili na comparo.hr@ccsupport.biz. Servis je dostupan samo korisnicima unutar Hrvatske, 18+.</small></p>
                    </div>
                  </div>
                </div>
              </div>
          </div>

      </div>);
  }

  async selectCard(e) {
    const cardToSelect = e.currentTarget;

    if(cardToSelect.classList.contains("card-selected")) return;


    const selectedCards = this.state.selectedCards;
    selectedCards.push(parseInt(cardToSelect.getAttribute("id")));
    this.setState({selectedCards: selectedCards});

    if(this.state.resultCards == null) {
      //const res = await fetch('http://love-tarot-hr.me/api/api.php?action=getTarotResult');
      const res = await fetch('https://hr.pitaj-karte.com/api/api.php?action=getTarotResult');
      const response = await res.json();
      this.setState({resultCards: response["result"]});
    }

    this._adjustSelectedCard(cardToSelect)

    if(this.state.selectedCards.length === 3) {
      this.cardsWrapperRef.classList.add("cards-reveal");
      this.displayResults();
    }

    this.setState()
  }

  _adjustSelectedCard(cardToSelect) {
    cardToSelect.querySelector('.card-front').src = this.state.resultCards['cards'][cardToSelect.getAttribute('id')]['src'];
    cardToSelect.parentNode.querySelector('.card-title').innerHTML = this.state.resultCards['cards'][cardToSelect.getAttribute('id')]['name'];
    cardToSelect.parentNode.querySelector('.card-subtitle').innerHTML = this.state.resultCards['cards'][cardToSelect.getAttribute('id')]['subtitle'];
    cardToSelect.classList.add('card-selected');
  }

  async displayResults(resultIsPreExistent) {
    this.resultSMSRef.setAttribute("href", "sms:862862?&body=" + this.state.resultCards["sms"]);
    this.setState({resultSMSCode : this.state.resultCards["sms"]});

    this.titleRef.style["opacity"] = 0;
    this.titleRef.style["max-height"] = '0px';
    this.rootRef.classList.add("root-cards-revealed");
    this.cardsWrapperRef.classList.add("cards-revealed");

    if(!resultIsPreExistent) {
      localStorage.setItem('selectedCards', JSON.stringify(this.state.selectedCards));
      localStorage.setItem('selectedCardsExpire', JSON.stringify(Date.now()));
      localStorage.setItem('resultCards', JSON.stringify(this.state.resultCards));
    }
  }
}
export default withRouter(Tarot);