import React from "react";
import { withRouter } from "react-router";
import './TarotDetail.css';

class TerotDetail extends React.Component {
  constructor(props) {
    super(props);
    const { match /*, location, history */} = props;
    this.state = {
        smsCode: match["params"]["smsCode"],
        resultCards: []
    };

    this.tarotDetailDescRef = null;
    this.tarotDetailTextRef = null;
    this.tarotDetailCardsRef = null;
  }
  componentDidMount() {
    this._getTarotDetail();
  }
  render() {
    return(
        <div className="container">
            <div className="header">
                <h2 className="title"><img src="/img/tarot-card-icong.png"/>LJUBAVNI TAROT</h2>
                <p>Ovaj ljubavni tarot dat će opis trenutnog stanja događaja u sferi ljubavi, otkrit će vaše istinske
                    želje, a u nekim slučajevima i brige i strahove koji ometaju vašu ljubvnu sreću. Predložit će vam
                    što treba učiniti da biste promijenili situaciju i izglede vaše veze u bližoj i daljoj
                    budućnosti</p>
                <h4 style={{margin: "30px 0"}}>Vaše karte i pozicije koje ste otvorili su:</h4>
            </div>
            <div className="tumacenje-karte">
                {this.state.resultCards.map((card, index) =>
                    <div className="row" style={{marginTop: "10px"}}>
                        <div className="col-xs-12">
                            <h4><strong><span className="pink">{["Prva", "Druga", "Treća"][index]} pozicija:</span> {card.subtitle} - <span className="pink">{card.name}</span></strong></h4>
                        </div>
                        <div className="col-xs-4">
                            <img src={card.src} className="img-responsive"/>
                        </div>
                        <div className="col-xs-8">
                            <p>{card.desc}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="baner">
                <a href="tel:064501503">
                    <img src="/img/baner.png" className="img-responsive"/>
                </a>
            </div>
        </div>
    );
  }

  async _getTarotDetail() {
    //const res = await fetch('http://love-tarot-hr.me/api/api.php?action=getTarotFullResult&smsCode=' + this.state.smsCode);
    const res = await fetch('https://hr.pitaj-karte.com/api/api.php?action=getTarotFullResult&smsCode=' + this.state.smsCode);
    const response = await res.json();

    const result = response["result"];
    this.setState({resultCards: Object.values(result['cards'])});

  }
}
export default withRouter(TerotDetail);