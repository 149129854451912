import React from "react";


class LoveCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isNotClicked: true
        }

        this.loveCalculator = this.loveCalculator.bind(this);
        this.resetFunction = this.resetFunction.bind(this);

        this.firstNameRef = null;
        this.loverNameRef = null;
        this.loveScoreRef = null;
    }

    render() {
        return(
            <div className="container wrapper">
                <div className="header">
                    <h2 className="title"><img src="/img/tarot-card-icong.png"/>LJUBAVNI TAROT</h2>
                    <h4>Saznajte što vas čeka u ljubavi</h4>
                    <p>Je li on/ona prava osoba za mene? Voli li me ili ne?<br/> Čeka li me brak i obitelj ili rastava?
                    </p>
                    <p><img src="/img/heart.png" className="img-responsive"/></p>
                </div>
                <div className="ljubavni-kalkulator">
                    <div id="love-calculator" className="calculator">
                        <form id="loveForm" className="" action="/" onSubmit="return loveValidation()" method="post">
                            <h2>Ljubavni kalkulator <i className="fas fa-heartbeat"></i></h2>
                            <h3 id="lovefinal" ref={(instance)=>{this.loveScoreRef = instance;}}></h3>
                            <h5 className="pink">Unesite imena ispod:</h5>
                            <h4>Tvoje ime</h4>
                            <input id="firstname" ref={(instance)=>{this.firstNameRef = instance;}} className="yourname name-box" type="text" name="firstName"
                                   placeholder="Tvoje ime"/>
                                <h4>Ime druge osobe</h4>
                                <input id="lovername" ref={(instance)=>{this.loverNameRef = instance;}} className="lovername name-box" type="text" name="lovername"
                                       placeholder="Ime simpatije"/>
                        </form>
                        <button id="love-button" onClick={this.loveCalculator} className="buttons" type="submit"
                                value="Submit"><i className="fas fa-heart"></i> Izračunaj
                        </button>
                        <button onClick={this.resetFunction} className="buttons" type="submit"><i
                            className="fas fa-heart-broken"></i> Reset
                        </button>
                        <h4 className="pink">ljubavni kalkulator vam omogućuje izračunavanje ljubavne kompatibilnosti i
                            vaših šansi za uspješnu vezu.</h4>
                        <p>Izračun ljubavi pomoću ljubavnog kalkulatora temelji se na određenom algoritmu. Nakon unosa
                            dva imena, ovaj kalkulator podudara se s imenom prve osobe prema nekim parametrima vezanim
                            uz ljubav, romantiku i vezu. Slično se ime vašeg partnera / ljubavnika podudara s istim
                            parametrima. Algoritam ljubavnog kalkulatora tada otkriva koliko parametara oboje imate
                            zajedničko. Na temelju ove analize kalkulator donosi zaključak i prikazuje postotak ljubavi
                            ili kompatibilnost. </p>
                    </div>
                </div>
            </div>
        );
    }

    async loveCalculator(e) {
        var yourName = this.firstNameRef.value;
        var loversName = this.loverNameRef.value;
        var loveScore = Math.random() * 100;
        var loveFinal = Math.round(loveScore);
        if (yourName === "") {
            alert("please enter your name");
        } else if (loversName === "") {
            alert("Please enter your lovers name");
        } else if (this.state.isNotClicked) {
            this.loveScoreRef.innerHTML = yourName + " i " + loversName + " vaš ljubavni rezultat iznosi " + loveFinal + " % ";
            this.setState({isNotClicked: false})
        } /*else {
            document.getElementById("lovefinal").innerHTML = yourName + " and " + loversName + " your lovescore is " + loveFinal + " % ";
        }*/
    }

    async resetFunction(e) {
        window.location.reload();
    }
}
export default LoveCalculator;