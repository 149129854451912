import React from "react";
import {isMobile} from 'react-device-detect';

class Terms extends React.Component {
  render() {
    return(
      <div class="wrapper">
        {/*<div class="header">*/}
        {/*    <nav class="navbar navbar-default">*/}
        {/*        <div class="container">*/}
        {/*            <div class="navbar-header">*/}
        {/*                <a class="navbar-brand" href="/"><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </nav>*/}
        {/*</div>*/}
        <div class="main-content">
          <div class="container uputstvo">
            <div class="col-sm-12"> 
                <div class="row">
                    <h2>Uvjeti korištenja za:</h2>
                    <p>1.	Uslugu sms servisa</p>
                    <p>2.	Uslugu telefonskog proricanja</p>

                    <h3>1. Usluga sms servisa</h3>
                    <p>Uslugu mogu koristiti osobe koje imaju 18 i više godina. Usluga je namijenjena samo za zabavu.</p>
                    <p>Servis je dostupan samo korisnicima unutar Hrvatske. WAP/GPRS promet plaća se prema cjeniku operatera. Cijena primljene poruke sa broja 862862 iznosi 9kn/sms. Ovo je pretplatnička usluga. Aktivacijom usluge možete primiti max. 2sms/tjedno. Za odjavu slijedite upute poslane u sms poruci nakon aktivacije servisa.</p>      <br />
      <h3>2. Usluga telefonskog proricanja</h3>
      <p>Usluga se može koristiti isključivo pozivom na brojeve povišene tarife.</p>
      <p>Uslugu mogu koristiti osobe koje imaju 18 i više godina. Ista osoba može koristiti Uslugu više puta. Pozivom na telefonski broj usluge pristajete na odredbe ovih Uvjeta, i sklapate ugovor sa Organizatorom.</p>
      <p>Način i cijena pozivanja za korištenje Usluge uz uključenje:</p>
          <p>Pozivom broja 064/501-503 s fiksne telefonske linije i s mobitela:</p>
          <p>Cijena poziva po minuti razgovora:</p>
          <p>– Za poziv s fiksnog telefona 3,49 HRK (PDV uključen),</p>
  <p>– za poziv s mobilnog telefona 4,78 HRK (PDV uključen).</p>
  <p>Cijena Usluge se iskazuje i naplaćuje na računu davatelja telekomunikacijske usluge – operatera pozivatelja. U slučaju neplaćanja ili kašnjenja s plaćanjem telefonskog računa, pozivatelju može biti uskraćeno daljnje pružanje Usluga, uz ostala prava telekomunikacijskog operatera.</p>
      <p>Upućivanjem telefonskog poziva na broj sa dodanom vrijednošću, pozivatelj prihvaća odredbe ovih Uvjeta i zaključuje Ugovor sa Organizatorom.</p>
      <p>Organizator nije odgovoran i ne preuzima nikakvu odgovornost za izjave koje su djelatnici Organizatora dali pozivatelju prilikom pružanja Usluge. Dobivene informacije koriste se na vlastitu odgovornost. Ne postoje znanstveni dokazi o djelovanju bilo kojih alternativnih tehnika proricanja i rituala. Djelatnici ne pružaju profesionalnu pomoć i profesionalne informacije, već savjete općenitog karaktera koji služe samo kao smjernice, kako bi korisnici dobili određene informacije, pomoću kojih mogu bolje razumjeti svoje stanje. Usluga je isključivo zabavnog karaktera. Ne možemo garantirati točnost pruženih informacija. Informacije ne treba smatrati zamjenom za pravne, medicinske, psihološke ili bilo koje druge profesionalne savjete. Savjeti individualnih djelatnika ne predstavljaju nužno stajalište Organizatora usluge.</p>
<br />
      <p>Organizator svih usluga: Comparo Media d.o.o., Tometići 15a, 51215, Kastav, OIB: 80948414608</p>
      <p>Tehnička podrška (davatelj usluge): NTH Mobile d.o.o., Međimurska 28, 42000 Varaždin: OIB: 12385860076, Tel: 042500871.</p>
      <p>Organizator ima pravo u svakom trenutku obustaviti ili prekinuti pružanje određene Usluge. Zadržavamo pravo promjene, ukidanja (privremenog i trajnog) bilo kojeg sadržaja ili usluge na web stranici bez obaveze prethodne najave.</p>
      <p>Odricanje od jamstva i ograničenje odgovornosti: Davatelj usluge, njegovi partneri i treće strane se izričito odriču bilo kakvog jamstva, izravna i posredna, uključujući, ali ne ograničavajući se na jamstvo točnosti, pouzdanosti, prikladnosti za određenu svrhu, ili bilo koje drugo jamstvo, usmeno, u pisanom ili u elektroničkom obliku, uključujući, ali ne ograničavajući se za točnost ili potpunost bilo koje informacije koje se nalaze u njoj ili se daju od strane web stranice/Usluge.</p>
      <p>Tekstovi koji se nalaze na web stranici su objekt autorskog prava i zaštićeni su Zakonom o autorskom i srodnim pravima</p>
      <p>Prihvaćanjem Uvjeta korištenja korisnici su suglasni da im organizator povremeno može dostavljati reklamne materijale na isključivo registracijski mail, SMS ili MMS porukom isključivo na brojeve mobilnih uređaja iz korisničke baze. Korisnik se u svakom trenutku može odjaviti sa reklamnih poruka slanjem ključne riječi „adv stop“ na kratki broj 862862.</p>
      <p>Organizator nije odgovoran za kvarove na mreži, telefonskoj liniji ili komunikacijske smetnje bilo koje vrste.</p>
      <p>Ukoliko imate više pitanja ili pritužbu, molimo Vas kontaktirajte službu za korisnike koja Vam je dostupna na e-mail comparo.hr@ccsupport.biz, ili na broj telefona 042500871 svakim radnim danom od 09:00-17:00 sati.</p>
      <p>Ova stranica za pružanje svojih usluga koristi kolačiće. Korištenjem ove web stranice pristajete na upotrebu kolačića. Možete ograničiti upotrebu kolačića u svakom trenutku pomoću internetskih postavki.</p>
      <p>Ukoliko se ne slažete sa ovim uvjetima korištenja molimo vas napustite stranicu!</p>


      </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Terms;