import React from "react";
import { Switch, Route } from "react-router-dom";
import Tarot from './pages/tarot/Tarot';
import TarotDetail from './pages/tarot-detail/TarotDetail';
import Help from "./pages/help/Help";
import Terms from "./pages/terms/Terms";
import LoveCalculator from "./pages/love-calculator/LoveCalculator";

export default function App() {
  return (
    <div>
      <Switch>
        <Route path="/tarot/:version">
          <Tarot />
        </Route>
        <Route path="/tarot">
          <Tarot />
        </Route>
        <Route path="/detaljna-analiza/:smsCode">
          <TarotDetail />
        </Route>
        <Route path="/calculator">
          <LoveCalculator />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/">
          <Tarot />
        </Route>
      </Switch>
    </div>
  );
}
